import { dismissComment, dismissComments } from "./notification-helpers";

$(document).on('turbo:load', ()=> {
  if ($("#js-top-navbar").length) { new Navbar($("#js-top-navbar")); }
});

class Navbar {
  constructor(element) {
    this.init_search_event_handlers = this.init_search_event_handlers.bind(this);
    this.init_dismiss_items = this.init_dismiss_items.bind(this);
    this.init_notifications = this.init_notifications.bind(this);
    this.init_clear_notifications = this.init_clear_notifications.bind(this);
    this.init_dismiss_items = this.init_dismiss_items.bind();
    this.notify_user = this.notify_user.bind(this);

    this.navbar_parent = element;
    this.search_container = element.find(".expandable-search-container");
    this.new_post_modal_submit_button = this.navbar_parent.find("#new-post-modal input[type=submit]");
    this.notifications_container = element.find(".user-notifications");

    this.init_search_event_handlers();
    this.init_notifications();
  }

  init_search_event_handlers() {
    const search_form = this.search_container.find(".js-navbar-search-form");
    const search_form_input = search_form.find("input[name=query]");
    const post_button = this.navbar_parent.find(".new-post");
    const greeting = this.navbar_parent.find(".navbar-welcome");

    search_form_input.on("focus", ()=> {
      this.search_container.addClass("expanded");
      post_button.hide();
      greeting.fadeOut();
    });

    search_form_input.on("blur", ()=> {
      this.search_container.removeClass("expanded");
      post_button.fadeIn();
      greeting.fadeIn();
    });
  }

  // We cache whether notifications exist with data-exists-stale.
  // If that's set to true, we need to fetch for whether they exist, which controls display of a red dot (active class).
  //
  init_notifications() {
    if (this.notifications_container.attr("data-exists-stale") == "true") {
      $.getJSON("/navbar_notifications/exists", data => {
        if (data.value > 0) {
          this.notifications_container.find(".user-notifications-toggle").addClass("active");
        }
      });
    }

    this.notifications_container.on("show.bs.dropdown", ()=> {
      $.get("/navbar_notifications", data => {
        this.notifications_container.find(".dropdown-menu").replaceWith(data);
        this.init_clear_notifications();
        this.init_dismiss_items();
        this.notify_user();
      });
    });
  }

  init_clear_notifications() {
    dismissComments(this.notifications_container, ()=> {
      this.notify_user();
    });
  }

  init_dismiss_items() {
    this.notifications_container.find(".acknowledgement").on("click", e => {
      dismissComment(e, this.notifications_container, ()=> {
        this.notify_user();
      });
    });
  }

  notify_user() {
    const comment_items = this.notifications_container.find(".comment-item");
    const maintenance_mode = this.notifications_container.find(".dropdown-menu").data("maintenance-mode");

    if (comment_items.length > 0) {
      this.notifications_container.find(".user-notifications-toggle").addClass("active");
      this.notifications_container.find(".clear-notifications").show();
      this.notifications_container.find(".no-notifications").hide();
    } else if (!maintenance_mode) {
      this.notifications_container.find(".user-notifications-toggle").removeClass("active");
      this.notifications_container.find(".clear-notifications").hide();
      this.notifications_container.find(".no-notifications").show();
    }
  }
}
