import { formParamsAsObject } from "shared/helpers/params";
import PdfGeneration from "./pdf-generation";
import contentExportPrechecks from './content-export-prechecks';

const exportStarting = (modal)=> {
  modal.find(".content-export-errors").text("").hide();
  modal.find(".spinner").show();
  modal.find("input[type=submit]").prop("disabled", true).val("Exporting...");
};

const exportFinished = (modal)=> {
  modal.find(".spinner").hide();
  modal.find(".content-export-errors").text("");
  modal.find("input[type=submit]").prop("disabled", false).val(modal.attr("data-original-value"));
  modal.modal("hide");
};

const exportFailed = (modal, err)=> {
  setTimeout(()=> {
    modal.find(".content-export-errors").text(err).show();
    modal.find(".spinner").hide();
    modal.find("input[type=submit]").prop("disabled", false).val(modal.attr("data-original-value"));
  }, 200);
};

export const exportHandlers = {
  xlsx: (form, modal)=> {
    console.log("Handling XLSX Export");

    exportStarting(modal);
    window.location = `${form.attr("action")}.xlsx?${form.serialize()}`;

    // Since XLS exports are generated on the fly (unlike PDF), wait a bit before closing the modal
    setTimeout(()=> {
      exportFinished(modal);
    }, 2000);
  },
  pdf: (form, modal)=> {
    console.log("Starting PDF Generation");

    const params = formParamsAsObject(form);

    const generation = new PdfGeneration(
      modal.attr("data-calendar-id"),
      modal.attr("data-generatable-type"),
      modal.attr("data-generatable-id"),
      Object.assign(JSON.parse(modal.attr("data-generatable-params") || "{}"), params),
      JSON.parse(modal.attr("data-generatable-options") || "{}")
    );

    generation.on("start", ()=> exportStarting(modal));
    generation.on("failure", err => exportFailed(modal, err));
    generation.on("success", data => {
      exportFinished(modal);
      window.location = data.response_url;
    });

    generation.generate();
  }
};

$(document).on("submit", ".content-export-modal form", async (e)=> {
  const form = $(e.currentTarget);
  const modal = form.closest(".content-export-modal");
  const params = formParamsAsObject(form);
  const format = modal.attr("data-format") || params.format;

  modal.attr("data-original-value", modal.find("input[type=submit]").val());

  e.preventDefault();

  console.log("Beginning Content Export: ", format);

  // Some exports may have pre-checks to run (like fetching required data from APIs for analytics)
  if (modal.attr("data-precheck")) {
    const precheck = contentExportPrechecks[modal.attr("data-precheck")];

    if (precheck) {
      console.log("Running Content Export Prechecks: ", modal.attr("data-precheck"));

      try {
        await precheck(form, modal);
      } catch (err) {
        console.error("Failed running generation precheck: ", err);
        exportFailed(modal, err);
        return false;
      }
    } else {
      console.error("Could not find generation precheck: ", modal.attr("data-precheck"));
      return false;
    }
  }

  // Delegate to export handler for the fixed or chosen format
  const handler = exportHandlers[format];

  if (handler) {
    handler(form, modal);
  } else {
    console.error("Could not find export handler for: ", format);
  }
});
