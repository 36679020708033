$(document).on('turbo:load', ()=> {
  if ($(".manage-identities tr.focused-message").length) {
    setTimeout((() => {
      $("html, body").animate({ scrollTop: $(".manage-identities tr.focused-message").offset().top - 100 }, "slow");
    }), 500);
  }

  const container = $(".js-identities-handlers");

  if (container.length) {
    const submitButton = container.find("input[type=submit], button[type=submit]");

    const hideNotices = ()=> {
      container.find(".facebook-group-app-warning").hide();
      container.find(".facebook-group-verification-error").hide();
    };

    const showActAsOptions = ()=> container.find(".act-as-options").show();
    const hideActAsOptions = ()=> container.find(".act-as-options").hide().find("select").val("");

    container.find("select#identity_target_type_id_name").on("change", e=> {
      hideActAsOptions();
      hideNotices();

      submitButton.prop("disabled", $(e.target).val() === "select");

      if (container.find("#facebook-profile-warning").length) {
        container.find("#facebook-profile-warning").toggle($(e.target).val().toString().length === 0);
      }
    });

    if (container.find("select#identity_target_type_id_name").val() !== "select") {
      container.find("input[type=submit], button[type=submit]").prop("disabled", false);
    }
  }
});
