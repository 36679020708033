$(document).on("turbo:load", ()=> {

  // Handle backwards compatibility with old bootstrap tabs and usage of anchors
  const { hash, pathname } = window.location;
  if ($(".calendar-settings").length && pathname.endsWith("edit") && hash) {
    const basePath = pathname.split("/").slice(0, -1).join("/");
    let page = "";

    switch (hash) {
      case "#details":
        page = "edit";
        break;
      case "#slots":
        page = "scheduling_slots";
        break;
      case "#publishing":
        page = "accounts";
        break;
      case "#administration":
        page = "collaborators";
        break;
      case "#roles":
        page = "roles"
        break;
      case "#workflow":
        page = "workflow";
        break;
      case "#ideas":
        page = "ideas";
        break;
    }

    if (page) {
      Turbo.visit(`${basePath}/${page}`);
      return;
    }
  }

  if ($(".calendar-settings.edit").length) { new CalendarSettings($(".calendar-settings.edit")); }
  if ($(".calendar-settings.roles").length) { new CalendarSettingsRoles($(".calendar-settings.roles")); }
  if ($(".calendar-settings.collaborators").length) { new CalendarSettingsCollaborators($(".calendar-settings.collaborators")); }
  if ($(".calendar-settings.post-variables").length) { new CalendarSettingsPostVariables($(".calendar-settings.post-variables")); }
});

class CalendarSettings {
  constructor(element) {
    this.container = element;
    this.handle_transfer_calendar_confirmation_form_submit = this.handle_transfer_calendar_confirmation_form_submit.bind(this);
    this.container.on("submit", ".transfer-calendar-confirmation-form", this.handle_transfer_calendar_confirmation_form_submit);
  }

  handle_transfer_calendar_confirmation_form_submit(e) {
    e.preventDefault();
    const to_user_id = e.currentTarget["to_user_id"].value;
    const { action } = e.currentTarget;

    $.get(action, { to_user_id }, data=> {
      const modal = $(data);
      $("#edit-calendar-container").append(modal);

      modal.on("hidden.bs.modal", () => modal.remove());

      modal.modal({show: true, backdrop: 'static', keyboard: false});
    });

    return false;
  }
}

class CalendarSettingsCollaborators {
  constructor(element) {
    this.container = element;
    this.init_edit_buttons = this.init_edit_buttons.bind(this);
    this.collaborator_edit_button = this.container.find(".manage-calendar-users .edit-trigger");
    this.init_edit_buttons();
  }

  init_edit_buttons() {
    this.collaborator_edit_button.on("click", function(e) {
      e.preventDefault();

      const trigger_button = $(this);

      trigger_button.siblings("form").find(".edit-role-cancel").on("click", e => {
        e.preventDefault();
        trigger_button.fadeIn();
        trigger_button.siblings("form").hide();
        trigger_button.siblings(".edit-role-current").fadeIn();
        trigger_button.closest("tr").find(".remove-trigger").fadeIn();
      });

      trigger_button.siblings("form").fadeIn();
      trigger_button.siblings(".edit-role-current").hide();
      trigger_button.closest("tr").find(".remove-trigger").hide();
      trigger_button.hide();
    });
  }
}

class CalendarSettingsRoles {
  constructor(element) {
    this.container = element;
    this.init_custom_role_list = this.init_custom_role_list.bind(this);
    this.init_custom_role_form = this.init_custom_role_form.bind(this);
    this.handle_custom_role_form = this.handle_custom_role_form.bind(this);
    this.init_custom_role_list();
    this.init_custom_role_form();
  }

  init_custom_role_list() {
    this.container.find(".roles-list .js-expand-role").click(e=> {
      e.preventDefault();
      $(e.target).closest(".panel").toggleClass("expanded");
    });
  }

  init_custom_role_form() {
    this.container.find("form.custom-role-form").each((i, el)=> {
      $(el).find("[data-toggle='popover']").popover({ html: true });
      this.handle_custom_role_form($(el));
    });
  }

  handle_custom_role_form(form) {
    const show_private = form.find("#role_permissions_comment_show_private");
    const make_private = form.find("#role_permissions_comment_make_private");

    const apply_private_dependency = () => {
      make_private.prop("disabled", !show_private.prop("checked"));
      if (!show_private.prop("checked")) { make_private.prop("checked", false); }
    };

    apply_private_dependency();
    show_private.on("change", e => apply_private_dependency());
  }
}

class CalendarSettingsPostVariables {
  constructor(element) {
    this.container = element;
    this.init_form_handling = this.init_form_handling.bind(this);
    this.init_form_handling();
  }

  init_form_handling() {
    this.container.find("form.post-variable-form").on("submit", e => {
      const nameFormGroup = $(e.currentTarget).find(".name-form-group");
      const name = nameFormGroup.find("input").val();

      nameFormGroup.removeClass("has-error").find(".form-field-error").text("");

      if (!name.match(/^[A-Za-z0-9\_]+$/)) {
        nameFormGroup.addClass("has-error").find(".form-field-error").text("Name must contain alphanumeric characters and underscores only.");
        e.preventDefault();
      }
    });
  }

  handle_custom_role_form(form) {
    const show_private = form.find("#role_permissions_comment_show_private");
    const make_private = form.find("#role_permissions_comment_make_private");

    const apply_private_dependency = () => {
      make_private.prop("disabled", !show_private.prop("checked"));
      if (!show_private.prop("checked")) { make_private.prop("checked", false); }
    };

    apply_private_dependency();
    show_private.on("change", e => apply_private_dependency());
  }
}
