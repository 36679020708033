import moment from 'moment';
import { getAutosaveKeyPrefix } from 'shared/helpers/autosave';

document.addEventListener("turbo:load", ()=> {
  const userDataElement = document.getElementById("user-data-tag");
  const userData = userDataElement ? JSON.parse(userDataElement.textContent) : {};

  for (const key of Object.keys(localStorage)) {
    if (!key.startsWith("loomly-user_")) {
      continue;
    }

    // Remove all autosaved drafts on sign out
    if (userData.sign_out_event) {
      localStorage.removeItem(key);
      continue;
    }

    // Remove all autosaved drafts for other users on sign in
    if (userData.sign_in_event) {
      const userPrefix = getAutosaveKeyPrefix();
      if (!key.startsWith(userPrefix)) {
        localStorage.removeItem(key);
        continue;
      }
    }

    try {
      // Remove all expired autosaved drafts
      const draft = JSON.parse(localStorage.getItem(key));
      const expiry = moment(draft.savedAt).add(6, 'hours');
      if (!expiry.isSameOrAfter(moment())) {
        localStorage.removeItem(key);
        continue;
      }
    } catch (err) {
      // Remove drafts with invalid JSON (could be tampered with)
      console.error(err);
      localStorage.removeItem(key);
      continue;
    }
  }
});
