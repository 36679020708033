$(document).on("change", "select[data-custom-role-select='true']", function(e) {
  let select_input = $(this)
  let previous_value = select_input.data("current-value")

  if (select_input.val() == "custom_role") {
    select_input.val(previous_value);
    select_input.change();

    let calendar_id = select_input.data("calendar-id");

    $.get(`/calendars/${calendar_id}/roles/promote_modal`, (data)=> {
      let modal = $(data);
      $("body").append(modal);
      modal.modal({ show: true, backdrop: 'static', keyboard: false });
      modal.on("hidden.bs.modal", () => modal.remove());
    });

    return;
  }

  select_input.data("current-value", select_input.val());
});
