$(document).on('turbo:load', ()=> {
  if ($("#calendar-header").length) { new CalendarHeader($("#calendar-header")); }
});

class CalendarHeader {
  constructor(element){
    this.init_modals = this.init_modals.bind(this);
    this.container = element;
    this.init_modals();
  }

  init_modals() {
    // Need to mount these modals to the body, boostrap issue causes modals to appear behind backstrop when parents are not position:static
    this.container.find("#filter-modal").appendTo("body");
    this.container.find("#quick-month-navigate").appendTo("body");
  }
}
