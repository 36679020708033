$(document).on('turbo:load', ()=> {
  if ($(".configure-twitter-trends").length) { new CalendarTwitterTrends($(".configure-twitter-trends")); }
});

class CalendarTwitterTrends {
  constructor(element) {
    this.init_load_children = this.init_load_children.bind(this);
    this.init_apply_selection = this.init_apply_selection.bind(this);
    this.container = element;
    this.init_load_children();
    this.init_apply_selection();
  }

  init_load_children() {
    this.container.find("select[name=place_1]").on("change", e => {
      const value = $(e.currentTarget).val();

      if (value.length && (value !== "1")) {
        $.getJSON(`/twitter_trends_places?parent_id=${value}`, data => {
          const place_2 = this.container.find("select[name=place_2]");
          place_2.show();
          place_2.html("");
          place_2.append("<option value=''>All Cities</option>");

          if (data.length === 0) {
            place_2.hide();
          } else {
            Array.from(data).map((entry) => place_2.append(`<option value='${entry.woeid}'>${entry.name}</option>`));
          }
        });
      } else {
        const place_2 = this.container.find("select[name=place_2]");
        place_2.html("");
        place_2.hide();
      }
    });
  }

  init_apply_selection() {
    const woeid = this.container.find("input[name='calendar[twitter_trends_woeid]']");

    this.container.find("select[name=place_1]").on("change", e=> {
      woeid.val($(e.target).val());
      this.container.find(".remove-button").toggle($(e.target).val().length > 0);
    });

    this.container.find("select[name=place_2]").on("change", e => {
      if ($(e.target).val().length) {
        woeid.val($(e.target).val());
      } else {
        woeid.val(this.container.find("select[name=place_1]").val());
      }
    });
  }
}
