$(document).on('turbo:load', ()=> {
  // NOTE: setting hash on iOS webview in mobile app
  // cases safari to open a new window. the window.webkit
  // check prevents this from happening
  if (!$("#wrapping-container-mobile").length) {
    $(".nav-tabs a").not("[data-disable-location-update=true]").on("click", e => {
      if (e.currentTarget.hash) {
        window.location.hash = e.currentTarget.hash;
      }
    });
  }

  if (document.location.hash.length && $(".nav-tabs").length) {
    $(".nav-tabs a[href='" + document.location.hash + "']").tab('show');
  }

  $(".nav-list a").on("click", function(e) {
    history.replaceState(null, null, $(this).data("target"));
  });

  if (document.location.hash.length && $(".nav-list").length) {
    $(".nav-list a[data-target='" + document.location.hash + "']").tab('show');
  }
});
