$(document).on("click", "#create-from-post-idea-modal input[type=submit]", e => {
  const modal = $(e.target).closest("#create-from-post-idea-modal");
  const subject = modal.find("input[name='subject']").val();
  const calendar_id = modal.find("input[name='to_calendar_id']").val();
  const date = modal.find("input[name='date']").val();
  if (calendar_id) {
    let url = `/calendars/${calendar_id}/posts/new?subject=${encodeURIComponent(subject)}`;

    if (date) { url += `&date=${encodeURIComponent(date)}`; }

    Turbo.visit(url);
  }
});
