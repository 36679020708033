import "expose-loader?exposes=Bugsnag!@bugsnag/js";
import "expose-loader?exposes=BugsnagPluginReact!@bugsnag/plugin-react";

import "channels";
import "bootstrap";
import "vendor/cocoon";

import RailsUJS from "expose-loader?exposes=RailsUJS!@rails/ujs";
RailsUJS.start();

import "@hotwired/turbo-rails"
import "default/turbo-config";

import "expose-loader?exposes=Tether!vendor/tether";
import "expose-loader?exposes=Shepherd!vendor/shepherd";

import "default/mixpanel-handling";
import "default/intercom-handling";
import "default/account-settings-handling";
import "default/alerts-handling";
import "default/all-calendar-handling";
import "default/analytics-handling";
import "default/autosave-handling";
import "default/calendar-header-handling";
import "default/calendar-logo-chooser-handling";
import "default/calendar-settings-handling";
import "default/calendar-twitter-trends-handling";
import "default/contact-submissions-handling";
import "default/cookie-settings-handling";
import "default/dashboard-handling";
import "default/forms-handling";
import "default/general-handling";
import "default/identities-handling";
import "default/invites-handling";
import "default/image-chooser-handling";
import "default/loomly-scroll-handling";
import "default/modals-handling";
import "default/multi-select-dropdown-handling";
import "default/navbar-handling";
import "default/navbar-handling";
import "default/new-feature-handling";
import "default/content-export-handling";
import "default/plan-comparison-table-handling";
import "default/popover-handling";
import "default/post-actions-handling";
import "default/post-filtering-handling";
import "default/post-ideas-handling";
import "default/roles-handling";
import "default/rss-feeds-handling";
import "default/service-tabs-handling";
import "default/subheader-handling";
import "default/subscriptions-handling";
import "default/tabs-handling";
import "default/tours-handling";
import "default/third-party-analytics-handling";
import "default/turbolinks-mobile-compatibility-shim-handling";
import "default/timezone-selection-handling";
import "default/inline-identities-handling";
