$(document).on("turbo:load", ()=> {
  // Need to opt-in to use boostrap popovers throughout app
  //
  // Using "body" as the container when container is not specified because without it the popover
  // will not always be shown above other elements such as sticky table headers
  // See https://github.com/twbs/bootstrap/issues/5889
  $("[data-toggle='popover']:not([data-container]):not([data-react])").popover({ container: "body" });
  $("[data-toggle='popover'][data-container]:not([data-react])").popover();

  // Activate Tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // Disable tooltips on mobile
  // Having tooltips on mobile would require two clicks to execute
  // https://github.com/twbs/bootstrap/issues/6232
  $('[data-toggle="tooltip"]').on('show.bs.tooltip', function(e) {
    if ('ontouchstart' in document.documentElement) { return e.preventDefault(); }
  });
});

$(document).on("turbo:before-visit", ()=> {
  // Hide popovers and tooltips before navigating away
  $("[data-toggle='popover']:not([data-container]):not([data-react])").popover("hide");
  $("[data-toggle='popover'][data-container]:not([data-react])").popover("hide");
  $('[data-toggle="tooltip"]').tooltip("hide");
});
