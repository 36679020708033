import { capitalize } from 'shared/helpers/strings';

$(document).on("turbo:load", ()=> {
  if ($(".image-chooser").length) {
    $(".image-chooser").map(function() { new ImageChooser($("#" + this.id)); });
  }
});

class ImageChooser {
  constructor(element){
    this.init_image_input = this.init_image_input.bind(this);
    this.container = element;
    this.init_image_input();
  }

  init_image_input() {
    const image_input = this.container.find(".js-image-input-field");
    const remove_file_button = this.container.find(".remove-file");
    const missing_image_path = this.container.attr("data-missing-image-path");
    const attachment_name = this.container.attr("data-attachment-name");

    image_input.on("change", e=> {
      if (e.target.files) {
        const file = e.target.files[0];
        try {
          const dataUrl = window.URL.createObjectURL(file);
          this.container.find(".js-image-preview").attr("src", dataUrl).show();
        } catch (err) {
          // Massive images may fail rendering. In that case, we'll ignore the exception and leave the placeholder.
          this.container.find(".js-image-preview").attr("src", missing_image_path).show();
        }
        this.container.find(".js-image-file-name").text(file.name).attr("title", file.name);
        remove_file_button.show();
        this.container.find(".remove-image-input").val(null);
      }
    });

    remove_file_button.on("click", e=> {
      image_input.val("");
      this.container.find(".js-image-preview").attr("src", missing_image_path).show();
      this.container.find(".js-image-file-name").text("No " + capitalize(attachment_name));
      remove_file_button.hide();
      this.container.find(".remove-image-input").val(true);
    });
  }
}
