import { formParamsAsObject } from "shared/helpers/params";

const fetchAnalyticsFor = (calendarId, provider)=> {
  console.log("Starting Analytics Prefetch: ", provider);

  return new Promise(function(resolve, reject) {
    const subscription = App.cable.subscriptions.create({ channel: "AnalyticsChannel", calendar_id: calendarId, provider: provider }, {
      rejected: () => {
        console.error("Analytics fetch post subscription rejected");
        resolve();
      },
      received: data => {
        console.log("Received Analytics Prefetch Response: ", provider, data);

        if (data.type == "fetch_complete") {
          subscription.unsubscribe();
          resolve(data);
        }
      }
    });

    // Automatically proceed if not done in 15 seconds
    setTimeout(()=> resolve(), 15000);

    $.post(`/calendars/${calendarId}/analytics/${provider}/fetch`);
  });
};

const contentExportPrechecks = {
  analytics: async (form, modal)=> {
    const calendarId = modal.attr("data-calendar-id");
    const params = formParamsAsObject(form);

    modal.find(".spinner").show();
    modal.find("input[type=submit]").prop("disabled", true).val("Fetching Latest...");

    const services = params.services || [];
    const promises = services.map(service => fetchAnalyticsFor(calendarId, service));

    await Promise.all(promises);
  }
};

export default contentExportPrechecks;
