import openPopupWindow from "./popup-window";

const setupInlineIdentityConnections = function() {
  window.onIndentityConnected = (calendarId, identityId, reconnecting)=> {
    const showCalendarCol = $(".manage-identities.show-calendar-col").length > 0;
    const viewByCalendar = $(`#collapse-calendar-${calendarId}`).length > 0;
    $.get(
      `/calendars/${calendarId}/identities/${identityId}/reload?reconnecting=${reconnecting}&show_calendar_col=${showCalendarCol}&view_by_calendar=${viewByCalendar}`
    ).fail(()=> {
      window.alert("An error occurred while refreshing your connection. Please refresh the page and try again.");
    });
  };
  window.onIndentityConnectionFailed = (message)=> {
    const alertElement =
      `<div class="flash">
        <div class="alert alert-error alert-dismissible">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
          <span class="alert-text">
            ${message}
          </span>
        </div>
      </div>`;

    $(".flash").replaceWith(alertElement);
    $("html, body").animate({ scrollTop: 0 }, "slow");
  };
};

$(document).on('turbo:load', ()=> {
  const inlineConnectionResultElement = $("#inline-connection-result");
  if (inlineConnectionResultElement.length && window.opener) {
    const inlineConnectionError = inlineConnectionResultElement.data("inline-connection-error");
    const calendarId = inlineConnectionResultElement.data("calendar-id");
    const identityId = inlineConnectionResultElement.data("identity-id");
    const reconnecting = inlineConnectionResultElement.data("reconnecting");

    if (inlineConnectionError) {
      if (window.opener.onIndentityConnectionFailed) {
        window.opener.onIndentityConnectionFailed(inlineConnectionError);
      } else {
        window.opener.location.reload();
      }
    } else {
      if (window.opener.onIndentityConnected) {
        window.opener.onIndentityConnected(calendarId, identityId, reconnecting);
      } else {
        window.opener.location.reload();
      }
    }

    window.close();
  }

  // We don't want to run the code below when inside post builder due to
  // the window callbacks possibly overriding the ones from post builder for
  // inline identities connections.
  if ($(".manage-identities").length > 0) {
    setupInlineIdentityConnections();
  }
});

$(document).on(
  "click",
  ".btn-inline-social-connection",
  (e)=> {
    // Those callbacks should have been previously defined but if not we make
    // sure they are defined now since we will need them from now on.
    if (!window.onIndentityConnected || !window.onIndentityConnectionFailed) {
      setupInlineIdentityConnections();
    }
    openPopupWindow($(e.target).closest(".btn-inline-social-connection").data("url"));
  }
);
