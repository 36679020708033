$(document).on("turbo:load", ()=> {
  let analytics_text = "", interactions_text = "", permissions;
  if (!$("body").data("shepherd")) { return; }

  const shepherd_data = $("body").data("shepherd");
  const current_tour = shepherd_data.tour;
  const tour_images = shepherd_data.images;
  const { tour_context } = shepherd_data;
  const { tour_variant } = shepherd_data;
  let wait_on = null;

  let tour = new Shepherd.Tour({
    defaults: {
      useModalOverlay: true
    }
  });

  // Need custom tracking of completion (otherwise not available)
  let completedTour = false;

  tour.on("complete", ()=> {
    completedTour = true;

    // Clean up
    $("#shepherd-under-layer").remove();

    // Ajax call to the ToursController on completed tour
    $.ajax({
      url: "/tours/",
      type: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        tour: {
          name: current_tour,
          status: "completed",
          variant: tour_variant
        }
      })
    });
  });

  const indefinite_article = (word) => {
    const vowels = "aieou";
    const first_letter = word[0].toLowerCase();

    if (vowels.indexOf(first_letter) > -1) {
      return "an";
    } else {
      return "a";
    }
  };

  // Shared Text Elements
  const shared_text = {
    collaborators: `It’s more fun working as a team! You can
      <span class='text-semi-bold'>invite collaborators</span>
      to your calendars as editors, contributors, clients, or viewers.
      <p>After you invite collaborators, you can
      <span class='text-semi-bold'>notify</span>
      them of any changes or if your post needs to be reviewed.</p>`,
    dashboard: `You can head to your <span class='text-semi-bold'>Dashboard</span> at any time to see a summary
      of your calendars and posts, along with useful surprises
      (including feature releases, video tutorials and Loomly Blog articles).`,
    analytics: "<p>After you’ve published 5 posts, you’ll be able to unlock <i class='fa fa-area-chart'></i>  Advanced Analytics View!</p>",
    interactions: "<p><i class='fa fa-comments'></i> Interactions allows you to respond to comments, @mentions or messages received in your social accounts.</p>"
  };

  // Common Style Elements
  const skip_tour_link = "<a href='#' onclick='Shepherd && Shepherd.activeTour && Shepherd.activeTour.complete(); return false;'>Skip Tour</a>";
  const background = "shepherd-enabled shepherd-target shepherd-background";
  const background_control_border = "shepherd-enabled shepherd-target shepherd-background with-control-border";
  const background_thin_rounded = "shepherd-background with-thin-border rounded";
  const background_logo = "shepherd-background with-thin-border rounded logo";
  const tour_step_counter_container_class = "tour-step-count-container";

  const tour_steps = (step_id) => {
    const steps_with_counter = tour.steps.filter(step => step.options.step_counter);
    const current_index = steps_with_counter.findIndex(step => step.id === step_id);

    return "<span class='text-italic'> " + (current_index + 1) + " of " + steps_with_counter.length + "</span> ";
  };

  const insert_tour_step_counter = step_id => $(`.${tour_step_counter_container_class}`).replaceWith(tour_steps(step_id));

  const title_with_steps = (tour_title, step_title) => `<span class='text-semi-bold'>${tour_title}</span> ` +
   `<span class='${tour_step_counter_container_class}'></span>` + " " + skip_tour_link +
  `</br>${step_title}`;

  const append_tour_close_button = () => $(".shepherd-content header").append(
    "<button type='button' class='closer' aria-label='Close' " +
    "onclick='Shepherd && Shepherd.activeTour && Shepherd.activeTour.complete();'>" +
    "<span aria-hidden='true'><i class='fa fa-times'></i></span>" +
    "</button>"
  );

  switch (current_tour) {
    case "user_welcome":
      switch (tour_variant) {
        case "invited":
          if (shepherd_data.redirect_to !== window.location.pathname) {
            Turbo.visit(shepherd_data.redirect_to);
            return;
          }

          if (shepherd_data.twofa_enforcement) {
            tour.addStep("calendar_requires_2fa", {
              title: "Calendar Requires two-factor Authentication",
              text: "The calendar you were invited to when joining Loomly requires users to have two-factor authentication enabled. " +
                    "To access it you will need to enable two-factor authentication on your account.",
              classes: "shepherd-medium",
              when: {
                show() {
                  append_tour_close_button();
                }
              },
              buttons: [
                {
                  text: "Take me to my Security Settings",
                  classes: "button small",
                  action() {
                    Turbo.visit("/account/password");
                    tour.complete();
                  }
                }
              ]
            });

          } else if (shepherd_data.no_calendar) {
            tour.addStep("unavailable_calendar", {
              title: "Calendar Unavailable",
              text: "The calendar you were invited to when joining Loomly is unavailable. " +
                    "You can still use Loomly and create new calendars.",
              classes: "shepherd-medium",
              when: {
                show() {
                  append_tour_close_button();
                }
              },
              buttons: [
                {
                  text: "Create calendar",
                  classes: "button small",
                  action() {
                    Turbo.visit("/calendars/new");
                    tour.complete();
                  }
                }
              ]
            });

          } else {
            const calendar_data = $("body").data("shepherd-calendar-data");
            permissions = calendar_data.permissions.calendar;
            const role_description = calendar_data.role_description ? "<p>" + calendar_data.role_description + "</p>" : "";

            tour.addStep("invited_calendar_welcome", {
              title: "Welcome to the " + calendar_data.name + " calendar 👋",
              text: "You’ve been added as " + indefinite_article(calendar_data.role_name) +
                    " <span class='text-semi-bold'>" + calendar_data.role_name + "</span>. " +
                    role_description +
                    "<p>Since this is your first time, let’s take a " +
                    "<span class='text-semi-bold'>quick tour.</span></p>",
              classes: "shepherd-medium",
              buttons: [
                {
                  text: "Start the tour",
                  classes: "button small",
                  action() {
                    tour.next();
                  }
                }
              ]
            });

            if (permissions.invite || permissions.poke) {
              tour.addStep("invited_calendar_1", {
                title: title_with_steps("Calendar Tour", "Collaborators"),
                text: shared_text.collaborators,
                attachTo: "#calendar-header #nav-collaborators bottom",
                classes: "shepherd-small shepherd-attached margin-10 shepherd-step-header",
                step_counter: true,
                when: { show() {
                  $("#calendar-header #nav-collaborators").addClass(background_thin_rounded);
                  insert_tour_step_counter("invited_calendar_1");
                  window.scrollTo(0, 0);
                }
                },
                buttons: [
                  {
                    text: "Next",
                    classes: "button small",
                    action() {
                      $("#calendar-header #nav-collaborators").removeClass(background_thin_rounded);
                      tour.next();
                    }
                  }
                ]
              });
            }

            if (permissions.analytics) {
              analytics_text = shared_text.analytics;
            }
            if (permissions.interactions) {
              interactions_text = shared_text.interactions;
            }

            tour.addStep("invited_calendar_2", {
              title: title_with_steps("Calendar Tour", "Explore Views"),
              text: `<p>In <i class='fa fa-calendar'></i> Calendar View, you can toggle
                the <i class='fa fa-clone'></i> Ungroup/Group icon to either
                display your post variations grouped as one post or ungrouped,
                for each social media channel.</p>
                <p>Besides  <i class='fa fa-calendar'></i> Calendar View, you can
                find your post in <i class='fa fa-th-list'></i> List View.</p>
                ${interactions_text}
                ${analytics_text}
                <p>If you need inspiration, check out <i class='fa fa-lightbulb-o'></i>
                Post Ideas View for daily post suggestions.</p>`,
              attachTo: "#calendar-header .controls-container.left bottom",
              classes: "shepherd-medium shepherd-attached shepherd-step-header",
              step_counter: true,
              when: {
                show() {
                  $("#calendar-header .controls-container.left .control-item").addClass(background_control_border);
                  insert_tour_step_counter("invited_calendar_2");
                  $(".shepherd-step").css("margin-left", $(".shepherd-enabled").width()/6);
                  window.scrollTo(0, 0);
                }
              },
              buttons: [
                {
                  text: "Previous",
                  classes: "button small secondary",
                  action() {
                    $("#calendar-header .controls-container.left .control-item").removeClass(background_control_border);
                    insert_tour_step_counter("invited_calendar_2");
                    tour.back();
                  }
                },
                {
                  text: "Next",
                  classes: "button small",
                  action() {
                    $("#calendar-header .controls-container.left .control-item").removeClass(background_control_border);
                    tour.next();
                  }
                }
              ]
            });

            tour.addStep("invited_calendar_4", {
              title: title_with_steps("Calendar Tour", "Dashboard"),
              text: shared_text.dashboard,
              attachTo: "#page-header .nav-bar-left bottom",
              classes: "shepherd-small shepherd-attached margin-10 shepherd-step-header",
              step_counter: true,
              when: {
                show() {
                  $("#page-header .nav-bar-left").addClass(background_logo);
                  insert_tour_step_counter("invited_calendar_4");
                  window.scrollTo(0, 0);
                }
              },
              buttons: [
                {
                  text: "Previous",
                  classes: "button small secondary",
                  action() {
                    $("#page-header .nav-bar-left").removeClass(background_logo);
                    tour.back();
                  }
                },
                {
                  text: "Done",
                  classes: "button small",
                  action() {
                    $("#page-header .nav-bar-left").removeClass(background_logo);
                    tour.next();
                  }
                }
              ]
            });
          }
          break;

        case "existing":
          tour.addStep("user_welcome_1", {
            title: "Welcome to the New Loomly! 👋",
            text: "We’ve got a new look and feel, but don’t worry. " +
                  "Your favorite features are still here. " +
                  "<p>Take a quick look to see what’s new!</p>",
            classes: "shepherd-medium",
            buttons: [
              {
                text: "Start",
                classes: "button small",
                action() {
                  tour.next();
                }
              }
            ]
          });

          tour.addStep("user_welcome_2", {
            text: "<img src=" + tour_images.menubar_1 + " alt='Menubar' class='nav-example'>" +
                  "<p>Introducing a new look for your calendar’s menu bar!</p>" +
                  "<p>You can still find your posts in</br>" +
                  "<i class='fa fa-calendar'></i>  <span class='text-semi-bold'>Calendar View</span>,  " +
                  "<i class='fa fa-th-list'></i>  <span class='text-semi-bold'>List View</span>, &  " +
                  "</p>" +
                  "<p>When your audience reacts to your posts, you can reply and manage their comments with  " +
                  "<i class='fa fa-comments'></i>  <span class='text-semi-bold'>Interactions.</span></p>" +
                  "<p>You'll be able to measure your success accurately with account, " +
                  "post & link metrics in  <i class='fa fa-area-chart'></i>  " +
                  "<span class='text-semi-bold'>Advanced Analytics View</span>!</p>" +
                  "<p>If you need inspiration, check out " +
                  " <i class='fa fa-lightbulb-o'></i> <span class='text-semi-bold'>Post Ideas View</span> " + `\
for daily post suggestions.`,
            classes: "shepherd-medium no-header padding-40",
            buttons: [
              {
                text: "Next",
                classes: "button small",
                action() {
                  tour.next();
                }
              }
            ]
          });

          tour.addStep("user_welcome_3", {
            text: "<img src=" + tour_images.menubar_2 + " alt='Menubar'>" +
                  "<p>Access your  <i class='fa fa-book'></i>  " + `\
<span class='text-semi-bold'>Library</span> ` +
                  "where you can upload photos and videos to be used later when " +
                  "creating new posts. Any media that you add to a post will " +
                  "automatically be added here as well.</p>" +
                  "<p>It’s more fun working as a team! You can  " +
                  "<i class='fa fa-users'></i>  <span class='text-semi-bold'>Invite Collaborators</span> " +
                  "to your calendars as editors, contributors, clients, or viewers.</p>" +
                  "<p>After you invite collaborators, you can  " +
                  "<i class='fa fa-users'></i>  <span class='text-semi-bold'>Notify</span> " +
                  "them of any changes or if your post needs to be reviewed." +
                  "<p>If you need a digital copy you can " +
                  "<i class='fa fa-arrow-circle-down'></i>  " +
                  "<span class='text-semi-bold'>Export</span> " +
                  "your calendar and analytics data in a simple PDF." +
                  "<p>We’ve also introducted new calendar  " +
                  "<i class='fa fa-cog'></i>  <span class='text-semi-bold'>Settings</span>. "+
                  "Take a look to configure!",
            classes: "shepherd-medium no-header padding-40",
            buttons: [
              {
                text: "Previous",
                classes: "button small secondary",
                action() {
                  tour.back();
                }
              },
              {
                text: "Got it",
                classes: "button small",
                action() {
                  tour.next();
                }
              }
            ]
          });
          break;
      }
      break;

    default:
      // No tour available. Clear out object so it doesn't try to write to db
      tour = {};
  }

  window.tour = tour;
  if ((typeof tour.steps === "undefined") || (tour.steps.length === 0)) { return; }

  // Because PostBuilder loads asynchronously, we may need to wait longer to attachTo
  if (wait_on) {
    var tourPoll = setInterval(function() {
      if ($(wait_on).length) {
        tour.start();
        return clearInterval(tourPoll);
      }
    }, 100);
  } else {
    tour.start();
  }
});
