import Cookies from "js-cookie";

$(document).on('turbo:load', () => {
  // Handle feature opt in link
  if ($("#feature-opt-in").length) {
    $("#feature-opt-in").on("click", e => {
      e.preventDefault();

      const feature = $(e.currentTarget).data('feature');

      Cookies.remove(`${feature}_disabled`);

      Mixpanel.track(`${feature}_enabled`, {}, { send_immediately: true }, () => {
        const url = window.location.href.replace(window.location.hash, "");
        window.location.replace(url);
      });
    });
  }

  if ($("#feature-opt-out-modal").length) {
    $("#feature-opt-out-modal form").on("submit", e => {
      e.preventDefault();

      const values = $(e.currentTarget).serializeArray();
      const { feature, feedback } = values.reduce((o, { name, value }) => ({...o, [name]: value }), {});

      Cookies.set(`${feature}_disabled`, "true", { expires: 120 });

      Mixpanel.track(`${feature}_disabled`, { feature_feedback: feedback }, { send_immediately: true }, () => {
        const url = window.location.href.replace(window.location.hash, "");
        window.location.replace(url);
      });
    });
  }
});
