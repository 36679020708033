import Clipboard from "clipboard";

$(document).on("turbo:load", function () {
  // Handle backwards compatibility with old bootstrap tabs and usage of anchors
  const { hash, pathname } = window.location;
  if ($(".account-settings").length && pathname.endsWith("edit") && hash) {
    const basePath = pathname.split("/").slice(0, -1).join("/");
    let page = "";

    switch (hash) {
      case "#profile":
        page = "edit";
        break;
      case "#password":
        page = "password";
        break;
      case "#notifications":
        page = "notifications";
        break;
      case "#apps":
        page = "apps";
        break;
      case "#branding":
        page = "branding";
        break;
      case "#billing":
        page = "billing";
        break;
    }

    if (page) {
      Turbo.visit(`${basePath}/${page}`);
      return;
    }
  }

  if ($(".account-settings").length) { new AccountSettings($(".account-settings")); }
  if ($(".account-settings.password").length) { new AccountSettingsPassword($(".account-settings.password")); }
  if ($(".account-settings.notifications").length) { new AccountSettingsNotifications($(".account-settings.notifications")); }
  if ($(".account-settings.apps").length) { new AccountSettingsApps($(".account-settings.apps")); }
  if ($(".account-settings.branding").length) { new AccountSettingsBranding($(".account-settings.branding")); }
  if ($(".account-settings.identities").length) { new AccountSettingsIdentities($(".account-settings.identities")); }
  if ($(".account-settings.billing").length) { new AccountSettingsBilling($(".account-settings.billing")); }
});

class AccountSettings {
  constructor(element) {
    this.container = element;
    this.edit_profile_submit();
  }

  edit_profile_submit = () => {
    this.container.find(".button-save-profile-edit").on("click", () => {
      RailsUJS.fire(this.container.find("#edit_user")[0], "submit");
    });
  }
}

class AccountSettingsPassword {
  constructor(element) {
    this.container = element;
    this.edit_password_submit();
    this.two_factor_go_setup();
    this.two_factor_go_confirm();
    this.two_factor_go_backup();
    this.two_factor_handle_enter();
  }

  edit_password_submit = () => {
    this.container.find("#edit-password-submit-button").on("click", () => {
      RailsUJS.fire(this.container.find("#edit-user-password")[0], "submit");
    });
  }

  two_factor_go_setup = () => {
    this.container.on("click", ".two-factor-go-setup", (e) => {
      e.preventDefault();
      e.stopPropagation();
      $(".two-factor-setup").show();
      $(".two-factor-confirm").hide();
      $(".two-factor-backup").hide();
    })
  }

  two_factor_go_confirm = () => {
    this.container.on("click", ".two-factor-go-confirm", (e) => {
      e.preventDefault();
      e.stopPropagation();
      $(".two-factor-setup").hide();
      $(".two-factor-confirm").show();
      $(".two-factor-backup").hide();
    })
  }

  two_factor_go_backup = () => {
    this.container.on("click", ".two-factor-go-backup", (e) => {
      e.preventDefault();
      e.stopPropagation();
      $(".two-factor-setup").hide();
      $(".two-factor-confirm").hide();
      $(".two-factor-backup").show();
    })
  }

  two_factor_handle_enter = () => {
    this.container.on("keypress", (e) => {
      if ($(".two-factor-backup").is(":visible") || e.keyCode !== 10 && e.keyCode !== 13) {
        return;
      }

      if ($(".two-factor-setup").is(":visible")) {
        e.preventDefault();
        e.stopPropagation();
        $(".two-factor-setup").hide();
        $(".two-factor-confirm").show();
        $(".two-factor-backup").hide();
      } else if ($(".two-factor-confirm").is(":visible")) {
        e.preventDefault();
        e.stopPropagation();
        $(".two-factor-setup").hide();
        $(".two-factor-confirm").hide();
        $(".two-factor-backup").show();
      }
    })
  }
}

class AccountSettingsNotifications {
  constructor(element) {
    this.container = element;
    this.check_mobile_notifications();
    this.check_slack_notifications();
    this.check_microsoft_notifications();
    this.check_email_notifications();
    this.init_select_all_notification_boxes();
  }

  check_mobile_notifications = () => {
    $(".js-calendar-panel").each(function () {
      const panel = $(this);
      if (panel.find(".js-push-notification-check:checked").length) {
        panel.find(".js-mobile-notifications-all").show();
      }
      if (!panel.find(".js-push-notification-check:not(:checked)").length) {
        return panel.find(".js-update-all-mobile-notifications").prop('checked', true);
      }
    });
  }

  check_slack_notifications = () => {
    $(".js-calendar-panel").each(function () {
      const panel = $(this);
      if (!panel.find(".js-slack-notification-check:not(:checked)").length) {
        return panel.find(".js-update-all-slack-notifications").prop('checked', true);
      }
    });
  }

  check_microsoft_notifications = () => {
    $(".js-calendar-panel").each(function () {
      const panel = $(this);
      if (!panel.find(".js-microsoft-notification-check:not(:checked)").length) {
        return panel.find(".js-update-all-microsoft-notifications").prop('checked', true);
      }
    });
  }

  check_email_notifications = () => {
    $(".js-calendar-panel").each(function () {
      const panel = $(this);
      if (panel.find(".js-email-notification-check:has(option[value='none']:not(:selected))").length || panel.find(".js-email-notification-check:has(option[value='']:not(:selected))").length) {
        return panel.find(".js-email-notifications-all").show();
      }
    });
  }

  init_select_all_notification_boxes = () => {
    // Push: trigger when selecting all
    this.container.find(".js-update-all-mobile-notifications").on("change", function (e) {
      const is_checked = $(this).prop('checked');
      const calendar = $(this).closest(".js-calendar-panel");

      calendar.find(".js-push-notification-check").each(function () {
        $(this).prop('checked', is_checked);
      });
    });

    // Push: trigger when selecting single
    this.container.find(".js-push-notification-check").on("change", function () {
      const calendar = $(this).closest(".js-calendar-panel");
      const all_checked = calendar.find('.js-push-notification-check:checked').length === calendar.find('.js-push-notification-check').length;
      calendar.find(".js-update-all-mobile-notifications").prop('checked', all_checked);
    });

    // Slack: trigger when selecting all
    this.container.find(".js-update-all-slack-notifications").on("change", function (e) {
      const is_checked = $(this).prop('checked');
      const calendar = $(this).closest(".js-calendar-panel");

      calendar.find(".js-slack-notification-check").each(function () {
        $(this).prop('checked', is_checked);
      });
    });

    // Slack: trigger when selecting single
    this.container.find(".js-slack-notification-check").on("change", function () {
      const calendar = $(this).closest(".js-calendar-panel");
      const all_checked = calendar.find('.js-slack-notification-check:checked').length === calendar.find('.js-slack-notification-check').length;
      calendar.find(".js-update-all-slack-notifications").prop('checked', all_checked);
    });

    // Microsoft: trigger when selecting all
    this.container.find(".js-update-all-microsoft-notifications").on("change", function (e) {
      const is_checked = $(this).prop('checked');
      const calendar = $(this).closest(".js-calendar-panel");

      calendar.find(".js-microsoft-notification-check").each(function () {
        $(this).prop('checked', is_checked);
      });
    });

    // Microsoft: trigger when selecting single
    this.container.find(".js-microsoft-notification-check").on("change", function () {
      const calendar = $(this).closest(".js-calendar-panel");
      const all_checked = calendar.find('.js-microsoft-notification-check:checked').length === calendar.find('.js-microsoft-notification-check').length;
      calendar.find(".js-update-all-microsoft-notifications").prop('checked', all_checked);
    });
  }
}

class AccountSettingsApps {
  constructor(element) {
    this.container = element;
    this.init_zapier_app_directory_modal();
  }

  init_zapier_app_directory_modal = () => {
    $('body').on('shown.bs.modal', "#zapier-app-directory-modal", function (e) {
      const modal = $(e.target);
      console.log("Loaded modal: ", modal);

      if (!modal.prop("data-zapier-loaded")) {
        const script = document.createElement("script");
        script.type = "module";
        script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
        document.head.appendChild(script);

        const stylesheet = document.createElement("link");
        stylesheet.rel = "stylesheet";
        stylesheet.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
        document.head.appendChild(stylesheet);

        const appDir = document.createElement("zapier-app-directory");
        appDir.app = "loomly";
        appDir.theme = "light";
        modal.find(".modal-body")[0].appendChild(appDir);

        modal.prop("data-zapier-loaded", true);
      }
    });
  }
}

class AccountSettingsBranding {
  constructor(element) {
    this.container = element;
    this.init_branding_clipboard();
  }

  init_branding_clipboard = () => {
    if ($(".branding-subdomain-clipboard").length) {
      const clipboard = new Clipboard(".branding-subdomain-clipboard", {
        text(trigger) {
          trigger = $(trigger);
          const subdomain = $(trigger.data("clipboard-target")).val();
          const domain = trigger.data("domain");
          const scheme = trigger.data("scheme");
          return `${scheme || "https"}://${subdomain}.${domain}`;
        }
      });

      clipboard.on("success", function (e) {
        $(e.trigger).hide();
        $(".clipboard-success-text").show();
        setTimeout((function () {
          $(".clipboard-success-text").hide();
          $(e.trigger).show();
        }), 1000);
      });
    }
  }
}

class AccountSettingsIdentities {
  constructor(element) {
    this.container = element;
    this.init_expand_all_panels();
  }

  init_expand_all_panels = () => {
    this.container.find("#expand-all-calendars").on("click", () => {
      this.container.find("#expand-all-calendars").hide();
      this.container.find("#collapse-all-calendars").show();
      this.container.find(".panel-collapse.collapse").collapse("show");
    });
    this.container.find("#collapse-all-calendars").on("click", () => {
      this.container.find("#collapse-all-calendars").hide();
      this.container.find("#expand-all-calendars").show();
      this.container.find(".panel-collapse.collapse").collapse("hide");
    });
  }
}

class AccountSettingsBilling {
  constructor(element) {
    this.container = element;
    this.init_payment_confirmation_modal();
  }

  init_payment_confirmation_modal = () => {
    this.container.find(".subscription-confirmation-modal").modal("show");
  }
}
