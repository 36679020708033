$(document).on("turbo:load", function() {

  if ((".js-toggle-quick-pricing").length) {
    $(".js-toggle-quick-pricing").click(function() {
      const yearly_toggle = $(".toggle-option-yearly");

      // Toggle the pill box month -> year selection
      yearly_toggle.toggleClass("selected");
      $(".toggle-option-monthly").toggleClass("selected");

      // Update SVGs
      $(".js-svg-path").toggleClass("svg-monthly-path");
      $(".js-svg-circle-focus").toggleClass("svg-price-circle-blob-monthly");
      $(".emphasis").toggleClass("monthly");
      $(".js-button-teams").toggleClass("monthly");

      // Hide and show annual totals
      $(".js-annual-total").toggle();

      // Update Pro or Focused Price option box
      if (yearly_toggle.hasClass('selected')) {
        $(".js-focus").addClass("focus-yearly").removeClass("focus-monthly");
        $(".fa-check").removeClass("monthly");
        $(".best-value").removeClass("monthly");
        $(".best-value > button").removeClass("monthly");
        $(".js-focus-column").removeClass("focus-monthly");
        $(".js-subheader-billing-period").text("billed yearly in USD");
        $("[data-show-when='yearly']").show();
        $("[data-show-when='monthly']").hide();

        $(".price-value-container .price-value").each(function(i, el){
          const price_value = $(el);
          price_value.text(price_value.data("yearly-price-per-month"));
        });

      } else {
        $(".js-focus").removeClass("focus-yearly").addClass("focus-monthly");
        $(".js-focus-column").addClass("focus-monthly");
        $(".fa-check").addClass("monthly");
        $(".best-value > button").addClass("monthly");
        $(".best-value").addClass("monthly");
        $(".js-subheader-billing-period").text("billed monthly in USD");
        $("[data-show-when='yearly']").hide();
        $("[data-show-when='monthly']").show();

        $(".price-value-container .price-value").each(function(i, el){
          const price_value = $(el);
          price_value.text(price_value.data("monthly-price-per-month"));
        });
      }
    });
  }

});
