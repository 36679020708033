$(document).on('turbo:load', function() {
  let invite_form;

  $(".new-invite-form").submit(e => {
    e.preventDefault();
    const form = $(e.target);
    const email = form.find("#invite_email").val();
    const calendar_id = form.find('select[name=calendar_id]').val() || form.data("calendar-id");
    const submit_button = form.find("input[type=submit]");

    submit_button.attr("data-original-value", submit_button.val());
    submit_button.prop("disabled", true).val("Inviting...");

    if (calendar_id) {
      $.get(`/calendars/${calendar_id}/invites/capabilities`, { email }, data=> {
        if (data.plan_capable) {
          form.get(0).submit();
        } else {
          submit_button.prop("disabled", false).val(submit_button.attr("data-original-value"));
          $.get(`/calendars/${calendar_id}/invites/plan_limit_modal`, { email }, data=> {
            const modal = $(data);
            $("body").append(modal);
            modal.modal({show: true, backdrop: 'static', keyboard: false});
            modal.on("hidden.bs.modal", () => modal.remove());
          });
        }
      });
    } else {
      form.get(0).submit();
    }
  });

  const refreshInviteFormForCalendar = function(form) {
    const calendar_id = form.find("#calendar_id").val();
    const submit_button = form.find("input[type=submit]");

    if (!(calendar_id.length > 0)) { return; }

    form.attr("data-calendar-id", calendar_id);
    form.find(".plan-limit-alert-container").html("");

    $.get(`/calendars/${calendar_id}/invites/capabilities`, data=> {
      if (data.plan_capable) {
        form.find(".select-email, .role-select").show();
        form.find(".role-select select option[value!='']").remove(); // Clear all select options except for default "Choose role:" option

        Array.from(data.roles).map((role) => {
          form.find(".role-select select").append($("<option />", { value: role.id, text: role.name }))
        });
      } else {
        $.get(`/calendars/${calendar_id}/invites/plan_limit_alert`, data => {
          form.find(".plan-limit-alert-container").html(data);
          submit_button.addClass("disabled").prop("disabled", true);
        });
      }
    });
  };

  $(".new-invite-form select[name=calendar_id]").on("change", e=> {
    const form = $(e.currentTarget).closest("form");
    refreshInviteFormForCalendar(form);
  });

  // If form already has a role selected, remove disabled property on submit button
  if ((invite_form = $("form#new_invite")).length) {
    if (invite_form.find("#invite_recipient_role_id").val()) {
      invite_form.find("input[type='submit']").removeClass("disabled").prop("disabled", false);
    }
  }

  // When role is selected, remove disabled property on submit button
  $("#invite_recipient_role_id").on("change", function(e) {
    const select_input = $(this);
    const form = select_input.closest("form");
    const calendar_role_id = select_input.val();
    const submit_button = form.find("input[type='submit']");

    if (calendar_role_id) {
      submit_button.removeClass("disabled").prop("disabled", false);
    } else {
      submit_button.addClass("disabled").prop("disabled", true);
    }
  });
});
