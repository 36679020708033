$(document).on("turbo:load", ()=> {
  if ($(".rss-feeds .edit_calendar #rss_feeds").length) {
    let max_rss_feeds = $(".rss-feeds .edit_calendar").data("max-rss-feeds");

    if ($(".rss-feeds .add_fields[data-association-insertion-node='#rss_feeds']").length) {
      $(".rss-feeds .edit_calendar #rss_feeds").on("cocoon:after-insert", (e, insertedItem)=> {
        if ($(".rss-feeds .nested-fields:visible").length >= max_rss_feeds) {
          event.preventDefault()
          $(".rss-feeds .add_fields[data-association-insertion-node='#rss_feeds']").hide();
          $(".rss-feeds .max-rss-feeds-note").show();
        }
      });
    }

    $(".rss-feeds .edit_calendar #rss_feeds").on("cocoon:after-remove", (e, insertedItem)=> {
      if ($(".rss-feeds .nested-fields:visible").length < max_rss_feeds) {
        $(".rss-feeds .add_fields[data-association-insertion-node='#rss_feeds']").show();
        $(".rss-feeds .max-rss-feeds-note").hide();
      }
    });
  }
});
