$(document).on('turbo:load', ()=> {
  $(".enforce-email-confirmation-modal").modal({
    show: true,
    keyboard: false,
    backdrop: 'static'
  });

  // Handle cancel subscription page exit survey
  if ($("#cancel-subscription-form").length) {
    $("#cancel-subscription-form .reason-code-input").on("change", e => {
      const code = $(e.currentTarget).val();

      // Disabling required specify fields so they don't block the form submission if they are not visible
      $(".specify-field-other").prop("disabled", true);

      $("#cancel-subscription-form input[type='submit']").prop("disabled", true);
      $("#cancel-subscription-form").find(".reason-explanation").hide().find(".reason-explanation-input").prop("disabled", true);
      $("#cancel-subscription-form").find(".reason-select").prop("disabled", true);
      if (code.length) {
        $("#cancel-subscription-form input[type='submit']").prop("disabled", false);
        $("#cancel-subscription-form").find(`.reason-explanation[data-code='${code}']`).show().find(".reason-explanation-input").prop("disabled", false);
        $("#cancel-subscription-form").find(`.reason-explanation[data-code='${code}']`).find(".reason-select").prop("disabled", false);
      }
    });

    $("#cancel-subscription-form .with-specify-field").on("change", e => {
      const code = $(e.currentTarget).val();
      const fieldSelector = $(e.currentTarget).data("field");

      if (code == "other") {
        $(fieldSelector).show();
        $(fieldSelector).prop("disabled", false);
      } else {
        $(fieldSelector).prop("disabled", true);
        $(fieldSelector).val("");
        $(fieldSelector).hide();
      }
    });
  }

  // Handle cancel subscription follow-up modal (which we show after canceling)
  if ($("#cancel-subscription-followup-modal").length) {
    $("#cancel-subscription-followup-modal").modal({show: true, backdrop: 'static', keyboard: false});
    $("#cancel-subscription-followup-modal").find(".request-followup").on("click", e => {
      e.preventDefault();
      $.post("/subscription/cancel_followup", () => $("#cancel-subscription-followup-modal").modal("hide"));
    });
  }
});
