/* Asynchronously generates a PDF. Observable events:
 * * `.on("start")`: When we're about to create the PdfGeneration.
 * * `.on("create")`: When the generation is completed (but not yet complete).
 * * `.on("finish")`: When the generation has either succeeded or failed.
 * * `.on("success")`: When the generation has successfully completed.
 * * `.on("failure")`: When the generation has failed.
 *
 */
export default class PdfGeneration {
  static TIMEOUT = 60000;

  constructor(calendarId, generatableType, generatableId, params = {}, options = {}) {
    this.calendarId = calendarId;
    this.generatableType = generatableType;
    this.generatableId = generatableId;
    this.params = params;
    this.options = options;

    this.generate = this.generate.bind(this);
    this.monitor = this.monitor.bind(this);
    this.timeout = this.timeout.bind(this);

    this.observers = [];
  }

  on(type, handler) {
    this.observers.push({ type, handler });
  }
  trigger(name, data) {
    this.observers.forEach(({ type, handler })=> {
      if (name == type) {
        handler(data);
      }
    });
  }

  generate() {
    $.ajax({
      url: `/pdf_generations`,
      type: "POST",
      data: JSON.stringify({
        calendar_id: this.calendarId,
        pdf_generation: {
          generatable_type: this.generatableType,
          generatable_id: this.generatableId,
          params: this.params || {},
          options: this.options || {}
        }
      }),
      contentType: "application/json; charset=utf-8",
      dataType: "JSON",
      beforeSend: () => {
        this.timeoutId = setTimeout(this.timeout, this.constructor.TIMEOUT);
        this.trigger("start");
      },
      success: (data, status, xhr)=> {
        console.log("[PdfGeneration ]Created PDF Generation: ", data, status);
        this.trigger("create", data);
        this.generation = data;
        this.monitor();
      },
      error: (data, status, xhr)=> {
        console.error("[PdfGeneration] Failed to Create PDF Generation: ", data, status, xhr);
        this.trigger("failure", data.responseJSON ? data.responseJSON.errors.join(", ") : "An unknown error has occurred");
        this.trigger("finish", null);
      }
    });
  }

  monitor() {
    console.log("PDF Generation Subscription Starting");

    this.subscription = App.cable.subscriptions.create({ channel: "PdfGenerationChannel", id: this.generation.id }, {
      rejected: () => {
        console.error("[PdfGeneration] PDF Generation subscription rejected"),
        this.trigger("error", "Subscription Rejected");
        this.trigger("finish", null);
      },
      received: data => {
        console.log("[PdfGeneration] PDF Generation Subscription Received: ", data);

        this.generation = data;
        this.trigger("received", data);

        if (data.successful && data.response_url) {
          this.timeoutId && clearTimeout(this.timeoutId);
          this.trigger("finish", data);
          this.trigger("success", data);

          this.subscription.unsubscribe();
          this.subscription = null;
        } else if (!data.successful && data.failure_message) {
          this.timeoutId && clearTimeout(this.timeoutId);
          this.trigger("finish", data);
          this.trigger("failure", data.failure_message);

          this.subscription.unsubscribe();
          this.subscription = null;
        }
      }
    });
  }

  timeout() {
    console.log("PDF Generation Timing Out");

    this.trigger("finish", {});
    this.trigger("failure", "PDF generation timed out");

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
