const safeParse = (text)=> {
  try {
    return JSON.parse(text);
  } catch (err) {
    console.error(err);
    return {};
  }
}

$(document).on('turbo:load', (e)=> {
  const userDataElement = document.getElementById("user-data-tag");
  const userData = safeParse(userDataElement?.textContent);

  /* HubSpot - Page View */
  if (window._hsq) {
    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['trackPageView']);
  }

  /* Google Analytics - Page View */
  if (window.gtag) {
    if (userData.id) {
      gtag('config', window.gaAccountCode, {
        'user_id': userData.id
      });
    } else {
      gtag('config', window.gaAccountCode);
    }
  }

  /* Google Analytics - Event */
  const googleAnalyticsEvent = document.getElementById("google-analytics-event");
  if (googleAnalyticsEvent) {
    const eventData = safeParse(googleAnalyticsEvent.textContent);

    if (window.gtag) {
      gtag('event', eventData.action, eventData.params);
    }

    if (eventData.action == "sign_up" && eventData.mutiny_enabled) {
      if (!window.mutinyTrackConversion) {
        (function() {
          var a="_mv";var b="mutiny.user.token";var c=window.location.search.replace("?","").split("&");var d=c.find(function(b){return b.startsWith(a)});var e=d&&d.split("=")[1];if(e){var f=new Date;f.setTime(f.getTime()+3*52*7*24*60*60*1000);var g=window.location.hostname;document.cookie="".concat(b,"=").concat(e,"; expires=").concat(f.toUTCString(),"; path=/; domain=.").concat(g.substring(g.lastIndexOf(".",g.lastIndexOf(".")-1)+1))}window.mutinyTrackConversion=function(a){var c="7dcbcc71b72d95ce";var d=(document.cookie.split("; ").find(function(a){return a.startsWith(b+"=")})||"").split("=")[1];var f=e||d||null;var g="https://api.mutinyhq.io/v2/i/conversions/t?token=".concat(c);var h=new XMLHttpRequest;h.open("POST",g,false);h.send(JSON.stringify({visitor_token:f,form_data:{name:a||"pixel"}}))};
        })();
      }

      window.mutinyTrackConversion("signup " + "[" + location.hostname + "]");
    }
  }

  /* Google Ads - Page View */
  if (window.gtag) {
    gtag('config', window.googleAdsAccount, { 'allow_enhanced_conversions': true });
  }

  /* Google Ads - Conversion */
  const googleAdsConversionEvent = document.getElementById("google-ads-conversion-event");
  if (window.gtag && googleAdsConversionEvent) {
    const eventData = safeParse(googleAdsConversionEvent.textContent);

    window.enhanced_conversion_data = {
      email: userData.email
    }

    gtag('set', 'user_data', {
      'email': userData.email
    });

    gtag('event', eventData.name, {
      'user_id': userData.id,
      'send_to': eventData.target,
      ...eventData.extra,
    });
  }

  /* LinkedIn - Page View */
  if (window.lintrk && !window._already_called_lintrk) {
    lintrk('track');
    window._already_called_lintrk = true;
  }

  /* Attribution - Page View */
  if (window.Attribution) {
    if (e.originalEvent.detail.timing.visitStart) {
      Attribution.page({ referrer: Turbo.navigator.currentVisit.referrer.href });
    } else {
      Attribution.page();
    }
  }

  /* Attribution - Event */
  const attributionAnalyticsEvent = document.getElementById("attribution-analytics-event");
  if (window.Attribution && attributionAnalyticsEvent) {
    const eventData = safeParse(attributionAnalyticsEvent.textContent);

    if (eventData.event === "sign_in") {
      Attribution.identify(userData.id.toString(), {
        name: userData.name,
        email: userData.email
      });
    }

    if (eventData.event === "sign_up") {
      Attribution.identify(userData.id.toString(), {
        name: userData.name,
        email: userData.email,
        createdAt: new Date() // Note: Only do this once!!!
      });

      Attribution.track("Signed Up");
    }
  }
});

$(document).on("turbo:before-visit", function() {
  /* LinkedIn - Reset tracker state */
  if (window.lintrk) {
    window._already_called_lintrk = false;
  }
});
