$(document).on("turbo:load", ()=> {
  const container = $(".calendar-logo-chooser");

  if (container.length) {
    initializeCalendarLogoChooser(container);
  }
});

const initializeCalendarLogoChooser = (container) => {
  const logo_input = container.find(".js-logo-image-input-field");

  logo_input.on("change", e => {
    if (e.target.files) {
      container.find(".upload-text").hide();
      try {
        const dataUrl = window.URL.createObjectURL(e.target.files[0]);
        container.find(".js-logo-image-preview").attr("src", dataUrl).show();
      } catch (err) {
        // Massive images may fail rendering. In that case, we'll ignore the exception and leave the placeholder.
      }
      container.find(".js-logo-image-file-name").text(e.target.files[0].name);
    }
  });
};
